/* Global */

* {
  font-family: Avenir, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

/* Chats Page */

.chats-page {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
}

.nav-bar {
  width: 100%;
  height: 66px;
  background-color: #002766;
}

.logo-tab {
  position: absolute;
  left: 22px;
  top: 12px;
  font-size: 32px;
  font-weight: 700;
  color: white;
}

.logout-tab {
  position: absolute;
  top: 22px;
  right: 22px;
  color: white;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

/* Login Page */

#login-page {
  background-image: linear-gradient(#a159fa, #096dd9);
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

#login-card {
  position: relative;
  top: calc(50vh - 144px);
  left: calc(50vw - 210px);
  padding-top: 36px;
  padding-bottom: 66px;
  width: 420px;
  text-align: center;
  background-color: white;
  border-radius: 22px;
}

.login-button {
  cursor: pointer;
  color: white;
  padding: 12px;
  border-radius: 8px;
  display: inline-block;
}

.facebook {
  background-color: #3b5998;
}

.google {
  background-color: #4285f4;
  color: white;
}

#loading-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgb(255, 255, 255, 0.22);
}

#loading-icon {
  font-size: 66px;
  position: relative;
  left: calc(50vw - 33px);
  top: calc(50vh - 22px);
}
